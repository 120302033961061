<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">상담 및 코칭 리포트 {{ pop.idx>0?'수정':'등록' }}</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view stageClass-table">
                    <tbody>
                        <tr>
                            <th width="120">작성일</th>
                            <td>{{ pop.info.wdate }}</td>
                        </tr>
                        <tr>
                            <th width="120">코칭일</th>
                            <td><carrot-date-picker v-model="pop.info.coach_date"></carrot-date-picker></td>
                        </tr>
                        <tr>
                            <th width="120">구분</th>
                            <td>
                                <select v-model="pop.info.ctype">
                                    <option value="">선택</option>
                                    <option :value="irow.code" v-for="(irow, i) in pop.ctype_list" :key="i">{{ irow.name }}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th width="120">코멘트</th>
                            <td>
                                <textarea v-model="pop.info.comment" class="w-100per h-100px"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <div v-if="pop.idx>0">
                    <button class="btn-default float-left" @click="pop.doDelete">삭제</button>
                    <button class="btn-default float-right" @click="pop.doModify">수정</button>
                </div>
                <div v-else class="txt-center">
                    <button class="btn-default dp-inblock" @click="pop.doSubmit">등록</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    name: 'CoachingAddPopup',
    components: {
        CarrotDatePicker
    },
    props: {
        idx: {
            type: [Number, String],
            default: 0
        },
        idx_hq: {
            type: [Number, String],
            default: 0
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            ctype_list : [],

            idx    : 0,
            idx_hq : 0,

            info : {
                ctype : '', 
                wdate : '', 
                coach_date : '', 
                comment : ''
            },

            doDelete: () => {
                if( confirm('삭제하시겠습니까?') == true ){
                    let params = {
                        idx    : pop.idx,
                        idx_hq : pop.idx_hq
                    };
                    axios.post('/rest/mypims/delCoaching', params).then((res) => {
                        if( res.data.err == 0 ){
                            pop.onClose();
                        } else {
                            if( res.data.err_msg ) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            doModify: () => {
                let params = {
                    idx : pop.idx,
                    idx_hq     : pop.idx_hq,
                    coach_date : pop.info.coach_date,
                    ctype      : pop.info.ctype,
                    comment    : pop.info.comment
                };
                if( !params.coach_date || !params.ctype || !params.comment ){
                    alert("모든 항목을 입력해 주세요.");
                    return;
                }
                axios.post('/rest/mypims/modCoaching', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("수정되었습니다.");
                        pop.onClose();
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit: () => {
                let params = {
                    idx : 0,
                    idx_hq     : pop.idx_hq,
                    coach_date : pop.info.coach_date,
                    ctype      : pop.info.ctype,
                    comment    : pop.info.comment
                };
                if( !params.coach_date || !params.ctype || !params.comment ){
                    alert("모든 항목을 입력해 주세요.");
                    return;
                }
                axios.post('/rest/mypims/addCoaching', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.info("등록되었습니다.");
                        pop.onClose();
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    idx : pop.idx
                };
                axios.get('/rest/mypims/getCoachingInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;

                        pop.ctype_list = res.data.ctype_list;
                    } else {
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            pop.idx    = props.idx,
            pop.idx_hq = props.idx_hq,

            pop.doSearch()
        })
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.btn-close { width:25px; height:25px; }
</style>