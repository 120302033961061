<template>
    <div v-if="view.isLoaded==true" class="con-wrap">
        <CoachingAddPopup :idx="coach.selected" :idx_hq="coach.idx_hq" v-if="coach.is_show_view" @close="coach.hideview"></CoachingAddPopup>
        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') 인사카드'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div class="tabDefult-col">
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab1-idx', params : { idx:view.idx }}"><button class="tab-default">인적사항</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab2-idx', params : { idx:view.idx }}"><button class="tab-default">인사History</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab3-idx', params : { idx:view.idx }}"><button class="tab-default">평가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab4-idx', params : { idx:view.idx }}"><button class="tab-default">휴가</button></router-link>
                    <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab5-idx', params : { idx:view.idx }}"><button class="tab-default on">교육</button></router-link>
                    <!-- <router-link :to="{ name : 'myPIMS-PersonalInfoList_tab6-idx', params : { idx:view.idx }}"><button class="tab-default">권한</button></router-link> -->
                </div>

                <div>
                    <table class="table-col">
                        <colgroup>
                            <col span="4">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>이수 교육 수</th>
                                <th>교육 이수 시간</th>
                                <th>필수 교육 시간</th>
                                <th>교육시간 이수율</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.info.complete_cnt  }}</td>
                                <td>{{ view.info.complete_time }}시간</td>
                                <td>{{ view.info.required_time }}시간</td>
                                <td>{{ view.info.complete_rate }}%</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <div class="block-title mt-50 mb-20">
                            상담 및 코칭 리포트
                            <button class="btn-default float-right ver-middle" @click="coach.showView(0)">리포트 등록</button>
                        </div>
                        <table class="table-col">
                            <colgroup>
                                <col span="4">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>작성일</th>
                                    <th>코칭일</th>
                                    <th>구분</th>
                                    <th>코치</th>
                                    <th>코멘트</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(irow, i) in coach.list" :key="i">
                                    <td>{{ irow.wdate }}</td>
                                    <td>{{ irow.coach_date }}</td>
                                    <td>{{ irow.ctype_txt }}</td>
                                    <td>{{ irow.ename + '(' + irow.kname + ')' }}</td>
                                    <td><span class="btn-view" @click="coach.showView(irow.idx)">View</span></td>
                                </tr>
                                <tr v-if="coach.total==0">
                                    <td colspan="5">코칭 정보가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="block-title mt-50 mb-20">교육이수 목록</div>
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="400">
                            <col width="*">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>교육기간</th>
                                <th>교육 명</th>
                                <th>이수시간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.sdate }}{{ irow.edate?'~'+irow.edate:'' }}</td>
                                <td>{{ irow.title }}</td>
                                <td>{{ irow.complete_time }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="4">이수한 교육이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CoachingAddPopup from '@/components/popup/myPIMS/PersonalInfoList/CoachingAddPopup.vue'
import {useStore} from "vuex";
import Swal from "sweetalert2";


export default {
    layout:"myPIMS",
    components: {
        CoachingAddPopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast();
        const store  = useStore();

        const view = reactive({
            isLoaded : false,

            idx : 0,

            info : {
                required_time : 0,
                complete_cnt  : 0,
                complete_time : 0,
                complete_rate : 0,
            },

            doSearch: () => {
                let params = { 
                    idx : view.idx,
                  user_position: store.state.idx_position,
                  user_sector: store.state.idx_sector,
                  user_office: store.state.idx_office,
                  user_team: store.state.idx_team
                };
                axios.get('/rest/mypims/myEducationInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.isLoaded = true;

                      //인사카드-tab4 열람권한 체크 추가
                      view.viewAuth=false;
                      if(store.state.isMaster || (view.info.auth.dept && view.info.auth.position)) //마스터권한 또는 (부문/본부/팀+직급 체크)
                        view.viewAuth=true;

                      if(store.state.pd_auth)
                        view.viewAuth=true;

                      if(view.viewAuth==false){
                        router.push({ name : 'main' });
                        Swal.fire({
                          title : '인사카드 상세',
                          text  : '접근 권한이 없습니다.'
                        });
                        return;
                      }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const coach = reactive({
            selected : 0,
            idx_hq   : 0,
            is_show_view : false,
            list : [], total : 0,

            doSearch: () => {
                let params = {
                    idx_hq : view.idx,
                };
                axios.get('/rest/mypims/getCoachingList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        coach.list  = res.data.list;
                        coach.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
              if(store.state.pd_auth !=true){
                Swal.fire({
                  title : '인사카드 상세',
                  text  : '수정 권한이 없습니다.'
                });
                return;
              }
                coach.selected = idx;
                coach.idx_hq   = view.idx;
                coach.is_show_view = true;
            },
            hideview: () => {
                coach.doSearch();
                coach.is_show_view = false;
                coach.selected = 0;
                coach.idx_hq   = 0;
            }
        });

        const bbs = reactive({
            page : 1,
            rows : 20,
            list : [], total : 0,

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },
            doSearch: () => {
                let params = {
                    page   : bbs.page,
                    rows   : bbs.rows,
                    idx_hq : view.idx,
                };
                axios.get('/rest/mypims/myEducationList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "myEduManagementView/" + idx;
                router.push({
                    name   : 'CARROTZone-myEduManagementView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                // location.href = "myEduManagementAdd";
                router.push('myEduManagementAdd');
            },
        });

        
        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearch();
            coach.doSearch();
            bbs.doInit();
        });

        return {view, coach, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>